import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import styles from './index.module.less'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { commonImgPath } from '@/shared/app-common'
import Image from 'next/image'
import { imgUrl } from '@/shared/utils'
import { useRouter } from 'next/router'

const colorBlocks = [
  {
    title: 'HubSpot',
    path: '/datify/hubspot',
    picture: commonImgPath + '/web-hubspot-icon.webp'
  },
  {
    title: 'Pixlr',
    path: '/datify/pixlr',
    picture: commonImgPath + '/web-pixlr-icon.webp'
  },
  {
    title: 'Ready Player Me',
    path: '/datify/ready-player-me',
    picture: commonImgPath + '/web-readyPlayerMe-icon.webp'
  },
  {
    title: 'Luma AI',
    path: '/datify/luma-ai',
    picture: commonImgPath + '/web-lumaAI-icon.webp'
  },
  {
    title: 'Artguru',
    path: '/datify/artguru',
    picture: commonImgPath + '/web-Artguru-icon.webp'
  },
]

export default function WebCarousel() {
  const [activeIndex, setActiveIndex] = useState(1) // Start with blue (middle) active
  const router = useRouter()

  const handleLeftClick = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % colorBlocks.length)
  }

  const handleRightClick = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + colorBlocks.length) % colorBlocks.length)
  }

  return (
    <div>
      <div className={styles.topContainer}>
        <h2>{`Wondering how it works? Take a tour of our database!`}</h2>
        <span>{`Preview`}</span>
      </div>

      <div className={styles.carousel}>

        <button
          className={styles.buttonLeft}
          onClick={handleLeftClick}
          aria-label="Previous"
        >
          <ArrowLeftOutlined />
        </button>

        <button
          className={styles.buttonRight}
          onClick={handleRightClick}
          aria-label="Next"
        >
          <ArrowRightOutlined />
        </button>

        <div className={styles.container}>
          <AnimatePresence initial={false}>
            {colorBlocks.map((block, index) => {
              const isActive = index === activeIndex
              const isNext = (index === (activeIndex + 1) % colorBlocks.length)
              const isPrev = (index === (activeIndex - 1 + colorBlocks.length) % colorBlocks.length)

              return (
                <motion.div
                  key={block.title + block.path}
                  className={styles.block}
                  initial={false}
                  animate={{
                    left: isActive ? '10%' : (isPrev ? '-70%' : '90%'),
                    opacity: 1,
                    scale: isActive ? 1 : 0.96,
                    zIndex: isActive ? 10 : ((isNext || isPrev) ? 5 : 1),
                  }}
                  transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                >
                  <Image
                    src={imgUrl(block.picture, 'big')}
                    alt={block.title}
                    layout="fill"
                    objectFit='fill'
                    style={{
                      borderRadius: '8px',
                      background: '#FFF',
                      cursor: 'pointer',
                      opacity: isActive ? 1 : 0.5,
                    }}
                    onClick={() => {
                      router.push(block.path)
                    }}
                  />
                </motion.div>
              )
            })}
          </AnimatePresence>
        </div>
      </div>
    </div>
  )
}

